import { ProjectInfo } from './utils'
import { formattedDescription, isUrl } from '../../common/utils/regex'
import { HighlightText } from '../../common/components/HightlightSpan'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { IconButton } from '@mui/material'
import FlipIcon from '@mui/icons-material/Flip'

type Props = {
  projectInfo: ProjectInfo
  onClickFlip?: () => void
}

export const ProjectCardFront = ({ projectInfo, onClickFlip }: Props) => {
  const { title, front } = projectInfo
  const { date, description, descriptionHightlight, details } = front

  return (
    <div className="flex items-center justify-center bg-white rounded-lg shadow-lg">
      <div
        className="flex flex-col p-4 sm:p-8 bg-white rounded-lg w-100 justify-between space-y-10"
        onClick={onClickFlip}
      >
        <div className="space-y-2 sm:space-y-4">
          <div className="flex flex-row justify-between">
            <div className="text-xl sm:text-4xl font-extrabold">{title}</div>
            <IconButton onClick={onClickFlip}>
              <FlipIcon className="text-blue-500" fontSize="medium" />
            </IconButton>
          </div>
          <div className="text-xs sm:text-xl text-gray-500 font-medium">
            {date}
          </div>
          <div className="text-xs sm:text-base whitespace-pre-wrap">
            <HighlightText
              text={formattedDescription(description)}
              highlights={descriptionHightlight ?? []}
            />
          </div>
        </div>
        <ul className="list-none space-y-0 sm:space-y-2 leading-tight sm:leading-normal">
          {details.map((detail) => (
            <li key={detail.label}>
              <span className="font-semibold pr-2 sm:text-base text-xs ">
                {detail.label}:
              </span>
              {isUrl(detail.text) ? (
                <a
                  href={detail.text}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline text-xs sm:text-base whitespace-normal break-all"
                >
                  {detail.text}
                </a>
              ) : (
                <span className="text-xs sm:text-base">{detail.text}</span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

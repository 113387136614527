import { Drawer, IconButton } from '@mui/material'
import { mobileProjectInfos, pcProjectInfos } from './data'
import { ProjectFrame } from './ProjectFrame'
import { ProjectGrid } from './ProjectGrid'
import { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import ImageIcon from '@mui/icons-material/Image'
import { useMediaQuery } from 'react-responsive'
import { ProjectImageList } from './ProjectImageList'

export const ProjectPages = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false)
  const [selectedProject, setSelectedProject] = useState<string>('')

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const handleClose = () => {
    setSelectedProject('')
    setIsOpen(false)
  }

  const handleSelectProject = (id: string) => {
    setSelectedProject(id)
  }

  const handleOpenImage = () => {
    setIsOpenImage((prev) => !prev)
  }

  const handleCloseImage = () => {
    setIsOpenImage(false)
  }

  useEffect(() => {
    if (selectedProject) {
      setIsOpen(true)
    }
  }, [selectedProject])

  const projectInfo = [...mobileProjectInfos, ...pcProjectInfos].find(
    (projectInfo) => projectInfo.id === selectedProject,
  )

  return (
    <div className="h-full w-full">
      <ProjectGrid onSelectProject={handleSelectProject} />
      <Drawer
        open={isOpen}
        onClose={handleClose}
        anchor="right"
        PaperProps={{
          style: {
            height: '100vh',
            width: '100vw',
            backgroundColor: 'black',
          },
        }}
      >
        <div className="absolute right-5 top-2">
          <IconButton onClick={handleClose}>
            <CloseIcon
              className="text-white  hover:text-blue-500"
              fontSize={`${isMobile ? 'medium' : 'large'}`}
            />
          </IconButton>
        </div>
        <div className="absolute right-5 top-14">
          <IconButton onClick={handleOpenImage}>
            <ImageIcon
              className="text-white  hover:text-blue-500"
              fontSize={`${isMobile ? 'medium' : 'large'}`}
            />
          </IconButton>
        </div>

        {!isOpenImage && selectedProject && projectInfo && (
          <ProjectFrame projectInfo={projectInfo} onClose={handleClose} />
        )}
        {isOpenImage && projectInfo && (
          <ProjectImageList
            projectInfo={projectInfo}
            onClose={handleCloseImage}
          />
        )}
      </Drawer>
    </div>
  )
}

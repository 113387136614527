import { ProjectInfo } from './utils'

const traB: ProjectInfo = {
  id: 'traB',
  title: 'TraB',
  type: 'MOBILE',
  front: {
    imageSize: 5,
    date: '2023.12 ~ 2024.02',
    description: `2024 GDSC Solution Challenge에 출품한 프로젝트로, 플로깅 (조깅을 하며 쓰레기를 줍는 캠페인)을 돕기 위해 기획한 앱 서비스입니다.
이를 위해 실시간 위치를 기반으로 사용자들이 쓰레기를 줍기 위해 이동하는 경로를 기록하고, 쓰레기를 줍는 활동을 기록할 수 있습니다.
이 과정에서 주운 쓰레기를 휴대폰 카메라로 촬영하며 학습된 AI를 통해 쓰레기의 종류를 분류한 뒤 그에 따른 보상을 얻는 소소한 성취감도 느낄 수 있는 장점이 있습니다.`,
    descriptionHightlight: [
      '2024 GDSC Solution Challenge',
      '플로깅',
      '조깅',
      '학습된 AI',
      '쓰레기',
    ],
    details: [
      {
        label: '주요 기능',
        text: '캐릭터 키우기, 조깅, 쓰레기 분류, 건강 기록',
      },
      {
        label: 'Frontend',
        text: 'Flutter, Riverpod, Google Map',
      },
      {
        label: 'Bakend',
        text: 'Nest.js, Postgresql, Google Cloud',
      },
      {
        label: 'AppStore',
        text: 'https://apps.apple.com/kr/app/trab/id6475684228',
      },
      {
        label: 'Github',
        text: 'https://github.com/TrashWouldBe',
      },
      {
        label: '소개영상',
        text: 'https://m.youtube.com/watch?v=dMWZC6rCCDw&feature=youtu.be',
      },
    ],
  },
  back: {
    taskDetails: {
      frontEnd: {
        contents: [
          'Google Map SDK를 활용하여 현재 위치 추적 및 거리 계산 기능 구현',
          '개인 캐릭터 키우기 및 캐릭터와 간단한 상호작용 기능 구현',
          'Riverpod을 사용한 MVVM 패턴 적용',
        ],
        highlights: [
          'Google Map SDK',
          '캐릭터',
          '조깅',
          '소셜 로그인',
          'Riverpod',
          'MVVM',
        ],
        contribute: 100,
      },
      backEnd: {
        contents: [
          'Kakao, Google, Apple 소셜 로그인 API 구현',
          'Nest.js를 활용한 간단한 CRUD API 구현',
        ],
        highlights: ['소셜 로그인', 'Nest.js', 'API', 'CRUD'],
        contribute: 50,
      },
    },
    takeaway: {
      contents: [
        'Google Map을 메인 기능으로 사용하여 현재 사용자의 위치를 추적하고 거리를 계산해야하는데, Android와 IOS의 위치 정보제공 연동 방식이 달라서 이 부분에서 많은 어려움을 겪었습니다. 하지만 이를 통해 익숙한 IOS 말고도 Android의 정책에 대해서도 조금 더 깊게 알 수 있는 기회였습니다.',
        '익숙하지 않은 Android의 배포에 자신이 없었는데, 배포와 개발에 성공적으로 마치며 앞으로 IOS, Android 모두 어렵지 않게 전과정을 도맡아 할 수 있을 거라는 자신감을 얻었습니다.',
        '같은 팀원들이 개발 경험이 없는 팀원들이라, 많은 부분의 책임을 맡게 되었습니다. 제가 모든 일을 하려기보단 역량에 맞게 업무를 분배했고, 각 팀원들이 더 잘 할 수 있는 일을 맡겨주어 함께 프로젝트를 만들어가기 위해 노력했던 것이 가장 큰 성과였습니다.',
      ],
      highlights: [
        'Google Map',
        'Android',
        'IOS',
        '소통',
        '책임',
        '역량',
        '팀원',
      ],
    },
  },
}

const walgaAdmin: ProjectInfo = {
  id: 'walgaAdmin',
  title: 'WalgaAdmin',
  type: 'MOBILE',
  front: {
    imageSize: 5,
    date: '2023.12 ~ 2024.08',
    description: `창업팀으로 진행했던 강아지 유치원 예약 및 관리를 위한 B2B CRM 앱 서비스입니다.
강아지 유치원을 운영하는 사장님들이 강아지를 예약하고 관리하는데 어려움을 겪는 것을 보고, 이를 해결하기 위해 시작하게 되었습니다.
실제로 카카오톡을 이용해서 예약 관리를 한다던가, 헬스 혹은 필라테스 예약 관리를 위해 출시된 앱을 통해서 예약 관리를 하고 계셨습니다.
꾸준히 강아지 유치원 사장님들에게 피드백을 받으며 서비스를 개선하려고 시도하였습니다.`,
    descriptionHightlight: ['유치원', '예약', 'CRM'],
    details: [
      {
        label: '주요 기능',
        text: '예약 및 고객관리, 채팅',
      },
      {
        label: 'Frontend',
        text: 'Flutter, Riverpod, GraphQL, GraphQL Codegen',
      },
      {
        label: 'Bakend',
        text: 'Node.js, Postgresql, Express, FireBase, AWS, GraphQL',
      },
      {
        label: 'AppStore',
        text: 'https://apps.apple.com/kr/app/walgaadmin/id6462873748',
      },
      {
        label: 'PlayStore',
        text: 'https://play.google.com/store/apps/details?id=com.walcome.walgaAdminApp',
      },
    ],
  },
  back: {
    taskDetails: {
      frontEnd: {
        contents: [
          '강아지 유치원 예약 운영 및 관리 기능 구현 및 캘린더를 사용한 일정 관리 기능 구현',
          '채팅 기능 구현 및 FCM을 통한 푸시알림 기능 구현',
          'Riverpod을 사용한 MVVM 패턴 적용',
        ],
        highlights: ['채팅', 'FCM', '캘린더', 'Riverpod', 'MVVM'],
        contribute: 100,
      },
      backEnd: {
        contents: [
          'AWS S3를 활용한 이미지 업로드 API 구현',
          'Kakao, Google, Apple 소셜 로그인 API 구현',
          'Node.js, Express, Apollo Server을 사용한 GraphQL API 구현',
          'FCM을 사용한 푸시알림 API 구현',
          'Subscription, Mutation을 사용한 실시간 채팅 구현',
          'Git Action을 사용한 스테이지 단계 별 서버 배포 및 CICD 관리',
        ],
        highlights: [
          '소셜 로그인',
          'API',
          'GraphQL',
          '서버 배포',
          'CICD',
          'FCM',
          'AWS S3',
          'Git Action',
        ],
        contribute: 70,
      },
    },
    takeaway: {
      contents: [
        '테크 리더로서 프로젝트의 전체적인 흐름을 파악하고, 특히 프로젝트의 요구사항에 맞게 아키텍처를 어떻게 설계하고 구현할지에 대해 많은 고민을 하였던 프로젝트입니다.',
        '개발 뿐 아니라 기획부터 디자인까지 전체적인 프로세스에 관여하며, 제품 중심적으로 사고하는 방식을 키울 수 있었던 프로젝트였습니다.',
        '팀원과의 소통이 원활하지 않아 프로젝트가 지연되는 경우가 많았는데, JIRA 및 Git Review Rule 등 여러가지 방법을 통해서 소통을 더욱 증진하고자 노력했고, 잦은 교류를 통해 프로젝트가 빠르게 진전되는 것을 체감하였습니다. 이를 통해 팀원들의 의견 종합 및 소통의 중요성을 깨달을 수 있게 되었습니다.',
        '빠른 MVP 출시를 위해, 어떠한 기술과 라이브러리를 도입해야 하는지 고민하고 다양한 방법을 시도해보았으며, 이를 통해 다양한 기술을 비판적으로 평가하고 장단점을 파악하는 능력을 기룰 수 있었던 프로젝트였습니다.',
      ],
      highlights: [
        '소통',
        '설계',
        '구현',
        '교류',
        '제품 중심',
        '의견',
        '프로세스',
        '분배',
        'JIRA',
        'Git Review Rule',
        'MVP',
        '장단점',
        '도입',
      ],
    },
  },
}

const walga: ProjectInfo = {
  id: 'walga',
  title: 'Walga',
  type: 'MOBILE',
  front: {
    imageSize: 5,
    date: '2023.12 ~ 2024.08',
    description: `창업팀으로 진행했던 WalgaAdmin과 연동되는 B2C 앱 서비스입니다.
강아지 유치원을 운영하는 사장님들이 편리하게 예약을 관리할 수 있도록, 유치원을 이용하는 고객들이 쉽게 예약하고 관리할 수 있는 서비스입니다.
WalgaAdmin과 공통되는 로직이 많기에, 여러 서비스에서 공통적으로 컴포넌트나 로직을 관리할 수 있는 방법에 대해서 많이 고민하게 된 프로젝트입니다.`,
    descriptionHightlight: ['B2C', '예약', '유치원', '공통'],
    details: [
      {
        label: '주요 기능',
        text: '예약, 유치원 찾기, 채팅',
      },
      {
        label: 'Frontend',
        text: 'Flutter, Riverpod, GrphQL, GraphQL Codegen',
      },
      {
        label: 'Bakend',
        text: 'Node.js, Postgresql, Express, FireBase, AWS, GraphQL',
      },
      {
        label: 'AppStore',
        text: 'https://apps.apple.com/kr/app/walga/id6462883045',
      },
      {
        label: 'PlayStore',
        text: 'https://play.google.com/store/apps/details?id=com.walcome.walgaApp&hl=ko',
      },
    ],
  },
  back: {
    taskDetails: {
      frontEnd: {
        contents: [
          '강아지 유치원 예약 및 관리 기능 구현 및 캘린더를 사용한 일정 관리 기능 구현',
          '채팅 기능 구현 및 FCM을 통한 푸시알림 기능 구현',
          'Riverpod을 사용한 MVVM 패턴 적용',
        ],
        highlights: ['채팅', 'FCM', '캘린더', 'Riverpod', 'MVVM'],
        contribute: 100,
      },
      backEnd: {
        contents: [
          'AWS S3를 활용한 이미지 업로드 API 구현',
          'Kakao, Google, Apple 소셜 로그인 API 구현',
          'Node.js, Express, Apollo Server을 사용한 GraphQL API 구현',
          'FCM을 사용한 푸시알림 API 구현',
          'Subscription, Mutation을 사용한 실시간 채팅 구현',
          'Git Action을 사용한 스테이지 단계 별 서버 배포 및 CICD 관리',
        ],
        highlights: [
          '소셜 로그인',
          'API',
          'GraphQL',
          '서버 배포',
          'CICD',
          'FCM',
          'AWS S3',
          'Git Action',
        ],
        contribute: 70,
      },
    },
    takeaway: {
      contents: [
        'WalgaAdmin과 공통되는 UI나 로직이 많은데, 이를 어떻게 공통으로 관리할지에 대해 많은 고민을 하게 되었습니다. 이를 통해 여러 서비스에서 공통적으로 사용되는 컴포넌트나 로직을 처음으로 Package방식으로 관리하게 된 프로젝트입니다.',
        'Riverpod을 사용한 상태관리와 MVVM패턴을 깊게 적용하면서, 상태관리에 대한 이해도를 높일 수 있었으며, 효율적인 상태관리와 그에 따른 좋은 아키텍처 구조는 무엇인지 진지하게 고민하게 되었던 프로젝트였습니다.',
        '사용자 경험 측면에서도 많은 고민을 하였는데, 사용자가 편리하게 서비스를 이용할 수 있도록 UI/UX를 고민하고, 사용자가 불편을 느낄 수 있는 부분을 개선하려고 꾸준히 노력했던 프로젝트였습니다.',
      ],
      highlights: [
        'MVVM',
        'Riverpod',
        'UI/UX',
        'Package',
        '상태관리',
        '아키텍처',
        '불편',
        '개선',
      ],
    },
  },
}

const actorsAndNetworkEfficiency: ProjectInfo = {
  id: 'actorsAndNetworkEfficiency',
  title: 'Actors And Network Efficiency',
  type: 'PC',
  front: {
    imageSize: 4,
    date: '2024.04 ~',
    description: `한양대학교 졸업 프로젝트로 진행한 프로젝트로, 감염병 대응 관계 분석 서비스입니다.
현대 사회는 SARS, MERS, COVID-19과 같은 팬데믹 문제가 발생하여 전 세계적으로 급격한 확산을 초래할 수 있습니다. 이로 인해 정확하고 신속한 정보 분석과 대응이 필요해지고 있습니다. 
프로젝트 진행 중에 계속해서 요구사항이 변경해서 수 차례의 기획 변경이 있었지만, 이로 인해 빠르게 요구사항에 대응할 수 있는 구조도 중요함을 느낄 수 있었습니다.`,
    descriptionHightlight: [
      '감염병 대응',
      '팬데믹',
      '정보 분석과 대응',
      '감염병 확산 경로',
    ],
    details: [
      {
        label: '주요 기능',
        text: '그래프 시각화, 데이터 분석, 그래프 커스터마이징',
      },
      {
        label: 'Frontend',
        text: 'React, Cytoscape',
      },
      {
        label: 'Backend',
        text: 'Nest.js, Postgresql, Redis',
      },
      {
        label: 'WebSite',
        text: 'https://algorithm.hanyang.ac.kr/C2S2/',
      },
    ],
  },
  back: {
    taskDetails: {
      frontEnd: {
        contents: ['Cytoscape를 활용하여 그래프 시각화 및 상호작용 기능 구현'],
        highlights: ['Cytoscape', '그래프'],
        contribute: 40,
      },
      backEnd: {
        contents: [
          '회원가입 및 로그인 API 구현',
          'Nest.js를 사용한 간단한 CRUD 구현',
          '그래프 데이터 캐싱을 위한 Redis 사용',
        ],
        highlights: ['Redis', 'Next.js', 'Passport', 'CRUD'],
        contribute: 100,
      },
    },
    takeaway: {
      contents: [
        '프로젝트 진행 중에 요구사항이 계속해서 변화하였고, DB Schema부터 프론트엔드까지 수차례의 변경이 있었습니다. 이로 인해 코드는 언제든 다시 수정될 수 있음을 인지하고, 이에 대한 대비책을 마련하는 것이 중요하다는 것을 배울 수 있었던 프로젝트였습니다.',
        '특히 RDBMS를 주로 사용해왔기에 자연스럽게 Postgresql을 사용했지만, 프로젝트 진행 중 DB Schema의 변화도 필요한 요구사항이 반복적으로 있었기에, 빠른 대응을 위해 NoSQL DB를 사용하였으면 더 효율적이었음을 느끼게 되었고 프로젝트의 특성에 따라 적절한 DB를 선택하는 것이 중요하게 작용할 수 있음을 깨닫게 되었습니다.',
        '그래프를 그리기 위해서는 한번에 모든 노드와 엣지 데이터를 가져와야 하기에 DB 부하와 네트워크 부하가 심했는데 batch query 및 redis를 사용해서 네트워크 부하를 줄이는 방법을 고민하고 적용해볼 수 있는 좋은 계기였습니다.',
        '클라우드를 통해 서버를 구성하는 것이 아닌 대학교 내의 컴퓨터 자원을 사용하였는데, 네트워크 설정부터 ssl 인증서 적용까지 직접 해보면서 서버 구축에 대한 이해도를 높일 수 있었던 프로젝트였습니다.',
      ],
      highlights: [
        'DB Schema',
        'Postgresql',
        'NoSQL',
        'DB',
        'RDBMS',
        'batch query',
        'redis',
        '컴퓨터 자원',
        '네트워크 설정부터 ssl 인증서',
      ],
    },
  },
}

const portfolio: ProjectInfo = {
  id: 'portfolio',
  title: 'Portfolio',
  type: 'PC',
  front: {
    imageSize: 5,
    date: '2024.08 ~',
    description: `개인 포트폴리오 사이트로, 제가 진행한 프로젝트들을 소개하고, 제가 사용한 기술 스택과 경험 및 이력을 소개하는 사이트입니다.`,
    descriptionHightlight: ['포트폴리오', '개인', '기술 스택', '경험', '이력'],
    details: [
      {
        label: '주요 기능',
        text: '프로젝트 소개, 기술 스택 소개, 경험 및 이력 소개',
      },
      {
        label: 'Frontend',
        text: 'React',
      },
      {
        label: 'WebSite',
        text: 'https://saewoohan.site/',
      },
    ],
  },
  back: {
    taskDetails: {
      frontEnd: {
        contents: ['React를 사용한 포트폴리오 사이트 구현'],
        highlights: ['React', '그래프'],
        contribute: 100,
      },
    },
    takeaway: {
      contents: [
        '개발자라면 포트폴리오를 자신이 직접 개발해서 호스팅 할 수 있어야 한다고 생각했고, 이를 통해 React를 사용한 포트폴리오 사이트를 직접 구현하게 되었습니다.',
        '저를 가장 잘 나타내는 디자인과 레이아웃은 무엇인지를 고민했고, 단순하고 깔끔한 것을 좋아하는 저의 성격을 최대한 반영하여 제작하였습니다.',
      ],
      highlights: ['포트폴리오', 'React', '디자인', '레이아웃', '깔끔', '성격'],
    },
  },
}

export const mobileProjectInfos: ProjectInfo[] = [traB, walgaAdmin, walga]

export const pcProjectInfos: ProjectInfo[] = [
  actorsAndNetworkEfficiency,
  portfolio,
]

import { ProjectInfo } from './utils'
import { IconButton } from '@mui/material'
import FlipIcon from '@mui/icons-material/Flip'
import { ListDetails } from './ListDetails'

type Props = {
  projectInfo: ProjectInfo
  onClickFlip?: () => void
}

export const ProjectCardBack = ({ projectInfo, onClickFlip }: Props) => {
  const { title, back } = projectInfo

  return (
    <div className="flex items-center justify-center bg-white rounded-lg shadow-lg">
      <div
        className="flex flex-col p-4 sm:p-8 bg-white rounded-lg justify-between space-y-2"
        onClick={onClickFlip}
      >
        <div className="space-y-[0px] sm:space-y-2">
          <div className="flex flex-row justify-between">
            <div className="text-xl sm:text-4xl font-extrabold text-blue-500">
              {title}
            </div>
            <IconButton onClick={onClickFlip}>
              <FlipIcon className="text-blue-500" fontSize="medium" />
            </IconButton>
          </div>
        </div>
        <ul className="list-none space-y-0 sm:space-y-2 leading-tight sm:leading-normal">
          <div className="space-y-1">
            <div className="sm:text-2xl text-lg font-bold pb-1">참여 기능</div>
            <ListDetails
              title={`Front-End (${back.taskDetails.frontEnd?.contribute ?? ''}%)`}
              details={back.taskDetails.frontEnd?.contents ?? []}
              highlights={back.taskDetails.frontEnd?.highlights ?? []}
            />
            <ListDetails
              title={`Back-End (${back.taskDetails.backEnd?.contribute ?? ''}%)`}
              details={back.taskDetails.backEnd?.contents ?? []}
              highlights={back.taskDetails.backEnd?.highlights ?? []}
            />
            <ListDetails
              title="ETC"
              details={back.taskDetails.etc?.contents ?? []}
              highlights={back.taskDetails.etc?.highlights ?? []}
            />
          </div>
          <div className="space-y-1">
            <div className="sm:text-2xl text-lg font-bold pb-1">느낀점</div>
            <ListDetails
              details={back.takeaway?.contents ?? []}
              highlights={back.takeaway?.highlights ?? []}
            />
          </div>
        </ul>
      </div>
    </div>
  )
}

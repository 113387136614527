import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const MacWindowFrame = ({
  children,
  className,
  onClick,
}: PropsWithChildren<Props>) => {
  return (
    <div className="p-4 rounded-lg">
      <div
        className={twMerge(
          clsx(
            'relative mx-auto bg-white rounded-lg overflow-hidden border border-gray-300',
            'transition duration-300 ease-in-out',
            'hover:shadow-2xl hover:scale-105',
            'active:scale-95',
            'w-[200px] h-[150px] sm:w-[200px] sm:h-[150px] md:w-[300px] md:h-[225px] lg:w-[350px] lg:h-[250px]',
          ),
          className,
        )}
        onClick={onClick}
        aria-label="Mac Window Frame"
      >
        <div className="absolute top-0 left-0 w-full h-8 bg-gray-100 rounded-t-lg flex items-center px-3 space-x-2">
          <div className="w-3 h-3 bg-red-500 rounded-full"></div>
          <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
          <div className="w-3 h-3 bg-green-500 rounded-full"></div>
        </div>
        <div className="pt-8 h-full w-full">{children}</div>
      </div>
    </div>
  )
}

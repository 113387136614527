import { useState } from 'react'
import { ProjectInfo } from './utils'
import { ProjectCardFront } from './ProjectCardFront'
import { ProjectCardBack } from './ProjectCardBack'
import ReactCardFlip from 'react-card-flip'

type Props = {
  projectInfo: ProjectInfo
  onClose?: () => void
}

export const ProjectFrame = ({ projectInfo, onClose }: Props) => {
  const [isFlipped, setIsFlipped] = useState(false)

  const handleFlip = () => {
    setIsFlipped(!isFlipped)
  }

  const handleBackgroundClick = () => {
    onClose?.()
  }

  const handleCardClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation()
  }

  return (
    <div
      className="flex justify-center items-center bg-black w-full h-full overflow-auto"
      onClick={handleBackgroundClick}
    >
      <div className="flex items-center justify-center w-[60vw] h-[70vh]">
        <div
          className="w-full h-full flex items-center justify-center"
          onClick={handleCardClick}
        >
          <ReactCardFlip
            isFlipped={isFlipped}
            infinite
            flipDirection="horizontal"
            containerStyle={{
              width: '100%',
              height: '100%',
            }}
          >
            <div className="w-full h-full flex items-center justify-center hover:cursor-pointer">
              <ProjectCardFront
                projectInfo={projectInfo}
                onClickFlip={handleFlip}
              />
            </div>

            <div className="w-full h-full flex items-center justify-center hover:cursor-pointer">
              <ProjectCardBack
                projectInfo={projectInfo}
                onClickFlip={handleFlip}
              />
            </div>
          </ReactCardFlip>
        </div>
      </div>
    </div>
  )
}

import { mobileProjectInfos, pcProjectInfos } from './data'
import { MacWindowFrame } from '../../common/components/MacWindowFrame'
import { IPhoneFrame } from '../../common/components/IPhoneFrame'

import { useState } from 'react'
import { ProjectTypeButtons } from './ProjectTypeButtons'

type Props = {
  onSelectProject?: (id: string) => void
}

export const ProjectGrid = ({ onSelectProject }: Props) => {
  const [devices, setDevices] = useState<'phone' | 'laptop'>('phone')

  return (
    <div className="h-screen flex justify-center items-center flex-col w-screen p-10 relative">
      <span className="text-3xl sm:text-4xl mt-4 font-black text-white text-center mb-10">
        {`Projects ${devices === 'phone' ? '📱' : '💻'}`}
      </span>

      <div className="flex justify-center items-center rounded-lg w-full bg-black">
        {devices === 'phone' ? (
          <div className="grid grid-cols-2 md:grid-cols-3 gap-10 bg-black rounded-[28px]">
            {mobileProjectInfos.map((project) => (
              <IPhoneFrame
                key={project.id}
                onClick={() => onSelectProject?.(project.id)}
              >
                <img
                  src={`img/${project.id}/thumbnail.png`}
                  alt={project.id}
                  loading="lazy"
                  className="rounded-lg object-cover w-full h-full"
                />
              </IPhoneFrame>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 p-5">
            {pcProjectInfos.map((project) => (
              <MacWindowFrame
                key={project.id}
                onClick={() => onSelectProject?.(project.id)}
              >
                <img
                  src={`img/${project.id}/thumbnail.png`}
                  alt={project.id}
                  loading="lazy"
                  className="rounded-lg object-cover w-full h-full"
                />
              </MacWindowFrame>
            ))}
          </div>
        )}
        <ProjectTypeButtons devices={devices} onChangeDevices={setDevices} />
      </div>
    </div>
  )
}
